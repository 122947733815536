import ParticipantListInformationProvider, { ParticipantListInformationProviderProps } from '@app/containers/providers/ParticipantListInformationProvider';
import { Colors, Tag, Tooltip } from '@blueprintjs/core';
import AvatarStack from '@components/AvatarStack';
import { AvatarInformation } from '@components/AvatarStack/types';
import Flex from '@components/Flex';
import Heading from '@components/Heading';
import RouterLink from '@components/RouterLink';
import DevText from '@components/Text';
import DeadlineTag from '@containers/components/TaskDeadlineTag';
import { Endpoints, imageHashPreview, taskStateInformation } from '@data/consts';
import { TaskKanbanBoardFormatted } from '@pages/TaskKanbanBoard/types';
import { TaskQueue, TaskState } from 'dy-frontend-http-repository/lib/data/enums';
import { TaskResource, TeamResource } from 'dy-frontend-http-repository/lib/modules/TaskKanban/resources';
import { ImageHashPreviewSize } from 'dy-frontend-shared/lib/data/valueObjects/ImageHashPreview/enums';
import moment from 'moment';
import React, { HTMLAttributes } from 'react';
import { StyledCard } from './styled';

export interface TaskKanbanCardProps {
    task: TaskResource;
    taskKanbanBoardFormatted: TaskKanbanBoardFormatted;
}

export type Props = HTMLAttributes<HTMLDivElement> & TaskKanbanCardProps;

const TaskKanbanCard: React.FC<Props> = ({ task, taskKanbanBoardFormatted, ...props }) => {
    const user = taskKanbanBoardFormatted.users[task.user_id];

    if (!user) {
        // TODO: handle error
        return null;
    }

    const renderTags = () => {
        const tags: React.ReactNode[] = [];

        // Add backlog tag
        if (task.queue === TaskQueue.BACKLOG) {
            tags.push(
                <Tag large={false} minimal className="mr-1">
                    Queued
                </Tag>
            );
        }

        // TODO: add priority tag
        // TODO: add "Has addons" tag

        return tags;
    };

    const renderCategory = () => {
        return (
            <Tooltip content={<DevText inline>Delivery: {task.category.average_delivery_days} day(s)</DevText>}>
                <DevText inline muted className="mr-2">
                    {task.category.title}
                </DevText>
            </Tooltip>
        );
    };

    const renderTitle = () => {
        let taskLink = Endpoints.TASK_MESSAGES;

        // If task delivered or staff is not check task information yet then go to information page
        if ([TaskState.DRAFT, TaskState.PUBLISHED, TaskState.DELIVERED].includes(task.state)) {
            taskLink = Endpoints.TASK_INFORMATION;
        }

        return (
            <RouterLink className="mb-1" color={Colors.WHITE} to={taskLink.replace(':taskId', task.id)}>
                <Heading type="h5">{task.title}</Heading>
            </RouterLink>
        );
    };

    const renderClientInformation = () => {
        let linkText = `${user.first_name} ${user.last_name}`;

        // If company name exist, then use it as a text for a link
        if (user.company_name.trim().length !== 0) {
            linkText = user.company_name;
        }

        // Client has not licences
        if (user.licences.length === 0) {
            return (
                <RouterLink
                    className="mr-1"
                    // TODO: what if workspace client
                    to={Endpoints.CLIENT_TASKS.replace(':clientId', user.id)}
                    color={Colors.GRAY2}
                    onClick={(e) => e.stopPropagation()}
                >
                    {linkText}
                </RouterLink>
            );
        }

        // Get client's first licence in array
        // TODO: what if there are more then 1 licence, which one should be use? What plan color then to use for RouterLink?
        const clientLicence = user.licences[0];

        const licencePlan = taskKanbanBoardFormatted.plans[clientLicence.plan_id];

        return (
            <div className="mr-1">
                <RouterLink
                    className="mb-small"
                    to={Endpoints.CLIENT_TASKS.replace(':clientId', user.id)}
                    onClick={(e) => e.stopPropagation()}
                    color={licencePlan ? licencePlan.color : Colors.WHITE}
                >
                    {linkText}
                </RouterLink>

                {/* TODO: do pausing whenever it's implemented on backend */}
                {/* <SubscriptionPausedTag className="mr-1" restoreAt="2023-07-06T17:53:23Z" /> */}
            </div>
        );
    };

    const renderLastActivity = () => {
        // TODO: get activity user information
        // TODO: implement whenever done on backend

        // const activityUser = task.activity_user;

        // if (activityUser === null) {
        //     return null;
        // }

        // const { first_name, last_name } = activityUser.public_profile;

        return (
            <DevText inline className="ml-auto">
                <Tooltip
                    content={
                        <DevText inline>
                            {moment(task.state_latest_transition_at).format('D MMM, YYYY - HH:mm')}
                        </DevText>
                    }
                >
                    <DevText inline muted className="ml-auto">
                        Last activity: {moment(task.state_latest_transition_at).fromNow()}
                    </DevText>
                </Tooltip>
            </DevText>
        );
    };

    const renderParticipants = () => {
        if (task.participants.length === 0) {
            return <Tag minimal>No participants</Tag>;
        }

        const taskParticipantsAvatarInformation: AvatarInformation[] = [];
        const participantListInformation: ParticipantListInformationProviderProps['participants'] = [];
        task.participants.forEach((p) => {
            const participant = taskKanbanBoardFormatted.participant_users[p.user_id];

            let participantTeam: TeamResource | null = null;
            if (participant.team_participation.length > 0) {
                // TODO: what if there are 2 teams, which team color should we show?
                participantTeam = taskKanbanBoardFormatted.teams[participant.team_participation[0].team_id];
            }

            if (participant) {
                taskParticipantsAvatarInformation.push({
                    id: participant.id,
                    name: `${participant.first_name} ${participant.last_name}`,
                    borderColor: participantTeam?.color,
                    src: participant.image_hash
                        ? imageHashPreview.userImage(participant.image_hash, ImageHashPreviewSize.SM)
                        : null,
                });

                const participantTeams: ParticipantListInformationProviderProps['participants'][number]['teams'] = [];
                for (const tp of participant.team_participation) {
                    // Get team information
                    const teamInformation = taskKanbanBoardFormatted.teams[tp.team_id];

                    if (teamInformation) {
                        participantTeams.push({
                            id: teamInformation.id,
                            name: teamInformation.title,
                            color: teamInformation.color,
                        });
                    }
                }

                participantListInformation.push({
                    userId: participant.id,
                    imageHash: participant.image_hash,
                    name: `${participant.first_name} ${participant.last_name}`,
                    role: participant.role,
                    teams: participantTeams,
                });
            }
        });

        return (
            <ParticipantListInformationProvider participants={participantListInformation}>
                <AvatarStack
                    withCounter
                    tight={false}
                    avatarSize={25}
                    maxAmountOfAvatarsToShow={3}
                    avatars={taskParticipantsAvatarInformation}
                />
            </ParticipantListInformationProvider>
        );
    };

    const renderDeadlineTag = () => {
        return <DeadlineTag minimal className="mr-2" finalizedAt={null} deadlineAt={task.deadline_at} />;
    };

    return (
        <StyledCard compact $borderColor={taskStateInformation[task.state].color} {...props}>
            <div>{renderTitle()}</div>
            <div>{renderCategory()}</div>
            <div className="mb-1">{renderClientInformation()}</div>
            <div className="mb-1">{renderLastActivity()}</div>
            <div className="mb-1">{renderTags()}</div>
            <Flex align="center" justify="space-between">
                {renderDeadlineTag()}
                {renderParticipants()}
            </Flex>
        </StyledCard>
    );
};

export default TaskKanbanCard;
